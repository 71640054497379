<template>
  <v-app id="inspire">
    <v-app-bar
        app
        color="white"
        flat
    >
      <v-container class="py-0 fill-height">
        <h1>Kerry Rodgers</h1>
       <v-spacer></v-spacer>

        <v-btn
            v-for="(link, i) in links"
            :key="link.title"
            :to="link.path"
            @click="loadAnimation(i)"
            text
        >
          {{ link.title }}
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn href="https://linkedin.com/in/kerryrodgers" icon>
          <v-icon class="blue--text">mdi-linkedin</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row class="fill-height">
          <v-col cols="2">
            <v-sheet rounded="lg">
              <lottie-animation :path="animation" auto-play />
            </v-sheet>
          </v-col>

          <v-col>
            <v-sheet
                min-height="70vh"
                rounded="lg"
            >
                <v-container class="fluid">
                <router-view></router-view>
                </v-container>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LottieAnimation from "../node_modules/lottie-vuejs/src/LottieAnimation.vue";
//import VueRssFeed from "../node_modules/vue-rss-feed/src/VueRssFeed.vue";

export default {
  components: {
    LottieAnimation,
    //VueRssFeed
  },
  methods: {
    loadAnimation(index) {
      this.animation = this.links[index].animation
    }
  },
  data: () => ({
    animation: 'animations/animation-1.json',
    links: [
      {
        title: 'Home',
        path: '/',
        animation: 'animations/animation-1.json'
      },
      {
        title: 'Portfolio',
        path: '/portfolio',
        animation: 'animations/animation-3.json'
      },
      {
        title: 'Resume',
        path: '/resume',
        animation: 'animations/animation-4.json'
      },
      {
        title: 'Contact',
        path: '/contact',
        animation: 'animations/animation-2.json'
      }
    ],
  }),
}
</script>

<style>
.vcenter {
  min-height: 10em;
  display: table-cell;
  vertical-align: middle
}
</style>